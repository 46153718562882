// custom functions
var FE = {
	location: window.location,

    Lightbox: {
      init: function () {
          $('.lightbox').magnificPopup({
              type:'image',
              gallery:{enabled:true}
          });
      }
    },

    MobileMenu: {
        init: function () {
            document.querySelector('.burger-icon').addEventListener('click', FE.MobileMenu.handleMenuAction);
            this.processSubmenus();
        },
        processSubmenus: function () {
            document.querySelectorAll('.btn-submenu-control').forEach(function (submenuBtn) {
                submenuBtn.addEventListener('click', FE.MobileMenu.handleSubmenuAction);
            });
        },
        handleMenuAction: function (e) {
            if (e.target.getAttribute('aria-expanded') === 'false') {
                FE.MobileMenu.openMenu(e.target);
            } else {
                FE.MobileMenu.closeMenu(e.target);
            }
        },
        openMenu: function (menuBtn) {
            menuBtn.setAttribute('aria-expanded', 'true');
            document.querySelector('#nav-main').classList.add('open');
            $('#nav-main').slideDown();
        },
        closeMenu: function (menuBtn) {
            menuBtn.setAttribute('aria-expanded', 'false');
            document.querySelector('#nav-main').classList.remove('open');
            $('#nav-main').slideUp();
        },
        handleSubmenuAction: function (e) {
            const submenuBtn = e.target;
            if (e.target.getAttribute('aria-expanded') === 'false') {
                FE.MobileMenu.openSubmenu(submenuBtn);
            } else {
                FE.MobileMenu.closeSubmenu(submenuBtn);
            }

        },
        openSubmenu: function (submenuBtn) {
            submenuBtn.setAttribute('aria-expanded', 'true');
            submenuBtn.nextElementSibling.style.display = 'block';
            setTimeout(function () {
                submenuBtn.nextElementSibling.classList.add('open');
            }, 100);
        },
        closeSubmenu: function (submenuBtn) {
            submenuBtn.setAttribute('aria-expanded', 'false');
            submenuBtn.nextElementSibling.classList.remove('open');
            // hide by display none, so keyboard navigation don't enter this section to step through
            setTimeout(function () {
                submenuBtn.nextElementSibling.style.display = 'none';
            }, 700);
        }
    },

	MobileMenuOld: {
		init: function() {
            FE.MobileMenu.indicateSubMenu();
			$('.burger-icon').on('click', function() {
                $('.nav-main').slideToggle();
            });
		},
        indicateSubMenu: function() {
            $('.nav-main > ul > li').each(function() {
                if ( $(this).find('ul').length ) {
                    $(this).prepend('<span>&gt;</span>');
                }
                $(this).find('span').on('click', function() {
                    $(this).siblings('ul').slideToggle();
                    $(this).parent().toggleClass('active');
                });
            });
        }
	},

    Iframe: {
	    init: function () {
            //$('#aktionskreis-companies').css('height', '100px');
            window.addEventListener("message", function(e) {
                // note: firebug and firefox console do not seem to output any debug here
                //console.log(e);

                  // Check that message being passed is the documentHeight
                  if (  (typeof e.data === 'string') && (e.data.indexOf('documentHeight:') > -1) ) {

                      // Split string from identifier
                      var height = e.data.split('documentHeight:')[1];

                      $('#aktionskreis-companies').css('height', parseInt(height) + 'px');

                  }
            }, false);
        }
    },

    LocationCategoryFilter: {
        init: function () {
            $('.location-category-filter-label').on('click', function() {
                $('#category-list').slideToggle();
            });
            $('.location-category-filter li button').on('click', function () {
                FE.LocationCategoryFilter.filterLocations( $(this).data('categoryFilter') );
            });
        },
        filterLocations: function (category) {
            $('.location-list li').each(function () {
                if ( $(this).data('categories').includes(category) ) {
                    $(this).show();
                } else {
                    $(this).hide();
                }
                //console.log( $(this).data('categories') );
            });
        }
    },

    HeroSlider: {
        init: function() {
            $('.header-secondary .slider').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                asNavFor: '.slider-for',
                dots: false,
                arrows: false,
                infinite: true,
                speed: 1000,
                autoplaySpeed: 5000,
                fade: true,
                slide: '.image-container',
                cssEase: 'linear',
                autoplay: true,
                centerMode: false,
                focusOnSelect: false,
                pauseOnHover: false
            });
        }
    },

    SearchBox: {
	    init: function () {
            $('.search-icon').on('click', function () {
                $('.search-box').toggle();
            });
        }
    },

    TourismMap: {
	    init: function () {
	        var mapContainer = document.querySelector('.tourism-map');
	        var previewImage = mapContainer.querySelector('img');
            previewImage.addEventListener('click', function () {
	            var iFrameHtml = '<iframe src="https://www.mediathek-vohburg.de/tourismus/tourismus?type=188" width="100%" height="600" border="0" id="city-library-tourism"></iframe>';
	            mapContainer.innerHTML = iFrameHtml;
            });
        }
    },

    YouTube: {
        youtubeContainer: null,
        youtubeId: null,

        init: function () {
            FE.YouTube.observeYouTubeOpen();
        },
        observeYouTubeOpen: function () {
            var elements = document.querySelectorAll('.youtube-embed');
            Array.prototype.forEach.call(elements, function(el, i) {

                el.addEventListener('click', function (e) {

                    e.preventDefault();

                    var urlParts = this.href;
                    var youTubeId = urlParts.split('v=')[1];

                    FE.YouTube.youtubeId = youTubeId;
                    FE.YouTube.youtubeContainer = this.parentNode;

                    FE.YouTube.getUserConsent();

                })
            });
        },
        getUserConsent: function () {

            var youTubeConsentHtml = 'Wir nehmen Ihren Datenschutz ernst.<br>An dieser Stelle laden wir ein externes Video von YouTube.<br>Nähere Infos dazu finden Sie in den <a href="https://policies.google.com/technologies/cookies?hl=de" target="_blank">Datenschutzbedingungen</a> von YouTube.';
            youTubeConsentHtml += '<div class="btn-group"><button class="btn-yt-confirm">Video laden</button><button class="btn-yt-deny">nein danke</button></div>';


            // Open YT consent
            $.magnificPopup.open({
                items: {
                    src: '<div class="youtube-consent">' + youTubeConsentHtml + '</div>',
                    type: 'inline'
                }
            });

            $('.btn-yt-confirm').on('click', function () {
                FE.YouTube.loadYouTubeVideo(FE.YouTube.youtubeContainer, FE.YouTube.youtubeId);
                $.magnificPopup.close();
            });

            $('.btn-yt-deny').on('click', function (){
                $.magnificPopup.close();
            });

        },
        loadYouTubeVideo: function (youTubeContainer, youTubeId) {
            youTubeContainer.innerHTML = FE.YouTube.getYouTubeEmbedCode(youTubeId);
        },
        getYouTubeEmbedCode: function (youTubeId) {
            return '<div class="video"><iframe width="100%" height="315" src="https://www.youtube-nocookie.com/embed/' + youTubeId + '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>';
        },
        storeYouTubeConsentInCookie: function () {
            document.cookie = 'youtube_consent=1;path=/;domain=kidnetting.de';
        }

    }

};

// init custom functions
(function ($, window, document, undefined) {

	$(function () {
        FE.Lightbox.init();
		FE.MobileMenu.init();
		FE.SearchBox.init();

		// check for page wirtschaft which includes external iframe
		if ( $('#page-6').length ) {
            FE.Iframe.init();
        }

        if ( $('.header-secondary .slider .image-container').length > 1 ) {
		    FE.HeroSlider.init();
        }

        if ( $('.location-category-filter').length ) {
            FE.LocationCategoryFilter.init();
        }

        if ( $('.youtube-embed').length ) {
            FE.YouTube.init();
        }

        if ( document.querySelector('.tourism-map') ) {
            FE.TourismMap.init();
        }

	});

}(jQuery, window, document));